.tab {
    color: black;
    cursor: pointer;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 16px;
    color: #9b9b9b;
    margin: 0 25px;
    padding-bottom: 3px;
}

.tab--active {
    color: black;
    margin: 0 25px;
    border-bottom: 2px solid #94c93d;
}

.calc_calc{
    margin: 0 25px;
    padding-bottom: 3px;
}
.calc_calc.active{
    border-bottom: 2px solid #94c93d;
}
.nt_mt_tabs{
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}
/*.tab:hover {*/
    /*color: white;*/
    /*border-bottom: 3px solid #98cb45;*/
/*}*/